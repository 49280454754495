* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle {
  margin: 25px 10px;
  width: 200px;
  color: #fff;
  font-size: 32px;
  line-height: 200px;
  text-align: center;
  height: 200px;
  border-radius: 100px;
}
.bg-yellow {
  background: #ffcc35;
}
.bg-green {
  background: #5bd5a0;
}
.bg-red {
  background: #eb3980;
}
.bg-sky {
  background-color: skyblue !important;
}

.acme {
  font-family: "Acme", sans-serif;
}

.roboto {
  font-family: roboto, sans-serif;
}

.clr-red {
  color: #ff1e28 !important;
}

.img-fluid {
  width: 100%;
  object-fit: cover;
}
