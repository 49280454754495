.safetytips {
  background-image: url("https://nirmal003.github.io/Images/safety1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.cntnr {
  width: 100%;
  height: 236px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pad {
  padding: 40px 0;
}

.safety {
  font-size: 2.5rem;
  font-weight: 600;
}

.heading1 {
  font-weight: 600;
  font-size: 2.5rem;
}

.font {
  font-size: 35px;
}

.block-head {
  position: relative;
  margin-bottom: 20px;
  padding: 0 0 20px;
  border-bottom: 1px #cac9c9 solid;
  font-weight: normal;
  color: #333e48;
}

li {
  list-style: none;
}

.icnclr1 {
  color: #559926;
  font-size: 30px;
}
.icnclr2 {
  color: #b61818;
  font-size: 30px;
}

.heading5 {
  font-size: 1.25rem;
}

.text4 {
  display: flex;
}

.pl {
  padding-left: 8px;
}
