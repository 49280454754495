.estimate_page::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 110vh;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  width: 100%;
}

.estimate_page {
  background-image: url("https://www.malathicrackers.com/images/parallax.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 110%;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  overflow-wrap: break-word;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0;
}

.estimate_con {
  position: absolute;
  max-width: 400px;
  height: 100%;
}

.estimate_head_con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 12px;
}

.form_con {
  padding: 4px 16px;
}

.form-control {
  appearance: auto;
  font-size: 1.15rem;
  padding: 0.425rem 0.55rem;
}

.submit_btn {
  background-color: #02f927d6 !important;
  color: black !important;
  font-weight: 600;
  font-size: 1.2rem;
}

.goback_btn {
  font-weight: 400;
  font-size: 1.2rem;
}
