.bg_overlay {
  position: relative;
}

.parall {
  background-image: url("https://nirmal003.github.io/Images/about2.jpg");
  height: auto;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.bg_overlay::after {
  z-index: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    290deg,
    rgba(52, 53, 60, 0.14) 45%,
    #002d58 45%
  );
}

.box-bg {
  position: relative;
  z-index: 1;
}

.para-lh {
  line-height: 28px;
}

.toll-free-cta::before {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  border-bottom-color: #fff;
  border-width: 20px;
}

.toll-free-cta {
  background: white;
  display: inline-block;
  text-align: center;
  padding: 10px 30px 10px;
  position: relative;
  margin-top: 15px;
  font-size: 20px;
}

.cardicn {
  font-size: 55px;
  color: #fff !important;
}

.card1st {
  background: #db2a1b;
}

.card2nd {
  background: #002d58;
}

.cardwrapper {
  padding: 3rem 2rem;
  border-radius: 30px;
  position: relative;
  height: 340px;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.padht {
  transition: 0.5s;
}

.padht:hover {
  transition: 0.5s;
  transform: translateY(-10px);
}

@media (min-width: 5px) and (max-width: 767.98px) {
  .bg_overlay::after {
    background-color: #002d58d1;
    background-image: none;
  }
}

@media (min-width: 5px) and (max-width: 767.98px) {
  .cardwrapper {
    height: 280px;
  }
}
