.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
}

.navbar .container {
  display: block;
}

.link {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 10px 25px !important;
  position: relative;
}
.nav-link {
  padding: 0;
}

.link:hover {
  background-color: rgb(239, 6, 220);
}

.navbar {
  background-color: #002d58;
}

/* .sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0;
} */

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler {
  border: none;
}

.toggle {
  padding: 13px;
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.cart_icon_con {
  width: 72px;
  height: 72px;
  border-radius: 50% 0 0 50%;
  position: fixed;
  z-index: 2;
  top: 82px;
  right: 0;
  background: #9e1a8a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopping_cart_icon {
  font-size: 40px !important;
}

.cart_qty {
  position: absolute;
  left: 55%;
  top: -0%;
  width: max-content;
  min-width: 28px;
  min-height: 28px;
  padding: 1px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(0, 0, 255);
}

@media (max-width: 1120px) {
  .crackers {
    display: none;
  }

  .header {
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: inline-block !important;
  }
}
